<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Create Coupon"/>

    <base-material-card
      icon="mdi-qrcode"
      title="Create Coupon"
      class="px-5 py-3">

      <v-form ref="form" @submit="submit" onSubmit="return false;">
        <v-container class="py-0">
          <VValidationErrors :validationErrors="validationErrors"></VValidationErrors>
          <v-row>
            <v-col cols="12" sm="12">
              <v-switch v-model="coupon.is_custom" class="ma-4" label="Use custom coupon"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" md="3">
              <v-select
                v-model="coupon.duration_id"
                label="Duration"
                :rules="rules.duration_id"

                class="purple-input"
                placeholder="Duration"
                :items="durations"
                item-text="duration_text"
                item-value="id"
                outlined
                required/>
            </v-col>


            <v-col cols="3" md="3">
              <v-text-field
                v-model="coupon.code"
                label="Coupon Prefix"
                :rules="coupon.is_custom ? rules.custom_code: rules.code"
                placeholder="Coupon Prefix"
                outlined
                required/>
            </v-col>

            <v-col cols="3" md="3">


              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="coupon.start_date"
                persistent
                width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="coupon.start_date"
                    :rules="rules.start_date"
                    label="Start Date"
                    readonly
                    outlined
                    required
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="coupon.start_date"
                               :min="nowDate"
                               scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(coupon.start_date)">OK</v-btn>
                </v-date-picker>
              </v-dialog>


            </v-col>


            <v-col cols="3" md="3">


              <v-dialog
                ref="dialog2"
                v-model="modal2"
                :return-value.sync="coupon.end_date"
                persistent
                width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="coupon.end_date"
                    :rules="rules.end_date"
                    label="End Date"
                    readonly
                    outlined
                    required
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="coupon.end_date" :min="coupon.start_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog2.save(coupon.end_date)">OK</v-btn>
                </v-date-picker>
              </v-dialog>


            </v-col>

            <v-col cols="3" md="3">
              <v-text-field
                v-model="coupon.note"
                label="Additional notes"
                placeholder="Notes.."
                outlined
                :class="{'hidden': !coupon.is_custom}"
              />
            </v-col>


            <v-col
              cols="12"
              class="text-right">
              <v-btn
                color="success"
                class="mr-0"
                @click="submit"
                :loading="loading"
                :disabled="loading"
              >
                Add Coupon
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>


    </base-material-card>


    <v-dialog
      v-model="dialog3"
      max-width="300"
    >
      <v-card>
        <v-card-title>
          Result

          <v-spacer/>

          <v-icon
            aria-label="Close"
            @click="closeAndRedirect"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-6 pt-12 text-center">
          Your Coupon Code Is
          <v-text-field v-model="generated_code" filled/>
        </v-card-text>

        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn color="success" text @click="closeAndRedirect">
            Ok
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>


  </v-container>
</template>


<script>

  import VValidationErrors from "../../components/base/VValidationErrors";
  export default {
    name: "CreteCoupon",
    components: {VValidationErrors},
    created() {
      this.getDurationList();
      this.coupon.code = this.makeKey(3);
    },
    data() {
      return {
        loading: false,
        durations: [],
        generated_code: '',
        coupon: {
          duration_id: '',
          code: '',
          start_date: new Date().toISOString().slice(0, 10),
          end_date: new Date((new Date()).getTime() + 365 * (60 * 60 * 24 * 1000)).toISOString().slice(0, 10),
          is_custom: false,
          note: ''
        },
        errorMessage: '',
        validationErrors: {},
        modal: false,
        modal2: false,
        nowDate: new Date().toISOString().slice(0, 10),
        dialog3: false,
        rules: {
          duration_id: [
            v => !!v || 'Duration is required',
          ],
          code: [
            v => !!v || 'Prefix Code is required',
            v => (v.length === 4 || v.length === 3) || 'Prefix Code must be at least 3 or 4 characters',
          ],
          custom_code: [
            v => !!v || 'Code is required',
            v => (v.length >= 3) || 'Code must be at least 3 characters',
          ],
          start_date: [
            v => !!v || 'Start Date is required',
          ],
          end_date: [
            v => !!v || 'End Date is required',
          ],
        },
      }
    },
    methods: {

      submit() {
        if (!this.$refs.form.validate()) {
          return;
        }

        this.loading = true;

        this.$authApi.post(`/coupons`, {
          ...this.coupon, ...{
            start_date: this.coupon.start_date + ' 00:01',
            end_date: this.coupon.end_date + ' 00:01',
          }
        })
          .then(res => {
            this.generated_code = res.data.data.coupon;
            this.dialog3 = true;
            this.loading = false;
            this.validationErrors = {};
          }).catch(error => {
          if (error.response.data.errors) {
            this.validationErrors = error.response.data.errors;
          }
          this.loading = false;
          if (error.response.data.message.message.message.code) {
            this.validationErrors = {
              code: [JSON.stringify(error.response.data.message.message.message.code).replace('\"', '')]
            };
          }
        })
      },
      getDurationList() {
        this.$authApi.get('/durations/active').then(res => {
          this.durations = res.data.data
        }).catch(err => {
        })
      },
      makeKey(length) {
        let result = '';
        let characters = 'abcdefghijklmnopqrstuvwxyz';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
      closeAndRedirect() {
        this.dialog3 = false;
        this.$router.push('/coupons')
      },


    }
  }
</script>


